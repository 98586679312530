<template>
  <div id="row" class="row top" v-if="!showTopBefore">
    <div id="outline-area" class="col-10 mr-0 outline-area">
      <div class="flex items-center">
        <div class="font-bold title-page">比較レポート</div>
      </div>
      <div class="video-report-area mt-4" v-show="isLoaded">
        <summary-chart
          :isGetVideosSuccess="isGetVideosSuccess"
          :videos="videos"
          :summaryAvg="summaryAvg"
          @selectVideo="onSelectVideo"
          :dataReport="dataReport"
          :adId="projectIdSelected"
          :buyerId="clientIdSelected"
          @loadVideosImages="loadedData"
          :checkedPrj="checkedPrj"
          :project="firstVideo"
          @selected = "getAvarage"
          @checkedAvgs = "getCheckedAvgIds"
        ></summary-chart>
      </div>
      <div class="capture-videos mt-5 has-no-image" v-if="!checkedVideos.filter(e => e.duration).length"
           v-show="isLoaded">
        <template v-if="checkedVideos.length">恐れ入りますが各シーンのイメージを反映するまでに少々お待ちください。動画登録の翌日に反映される予定です。</template>
        <template v-else>動画を選択するとシーン一覧がこちらに表示されます</template>
      </div>
      <div class="capture-videos has-video-selected mt-5" v-else v-show="isLoaded">
        <div class="type-reaction">
          <div v-if="firstVideo && firstVideo.interaction_btn_1 && Object.keys(firstVideo.interaction_btn_1).length"
               :class="colorInteraction.rank_2 + ' mr-2'">
            {{ textInteraction.rank_2 }}
          </div>
          <div v-if="firstVideo && firstVideo.interaction_btn_2 && Object.keys(firstVideo.interaction_btn_2).length"
               :class="colorInteraction.rank_3">
            {{ textInteraction.rank_3 }}
          </div>
        </div>
        <div class="list-video-captures">
          <capture-video
            v-for="(video, index) in checkedVideos.filter(e => e.duration)"
            :key="'video-' + video.id"
            :videoImage="video"
            :index="index"
            :step="currentImageStep"
            :interactionType="dataReport.interaction_type"
            :checkedVideosLength="checkedVideos.filter(e => e.duration).length"
            :firstVideo="firstVideo"
          ></capture-video>
        </div>
        <div class="btn-next">
          <i
            class="icon icon-prev"
            v-if="currentImageStep"
            @click="prevImages"
            data-cut-scene="action"
          ></i>
          <i
            class="icon icon-next"
            v-if="hasNextImages"
            @click="nextImages"
            data-cut-scene="action"
          ></i>
        </div>
      </div>
<!--      <div class="table-project" v-show="isLoaded && videos.length">
            <project-table :listVideo="videos" :checkedVideos="checkedVideos" :dataReport ="dataReport"></project-table>
          </div>
          <report-interaction-top
            v-if="isGetVideosSuccess"
            v-show="isLoaded"
          ></report-interaction-top>
          <segments-project
            :videos="videos"
            :adId="projectIdSelected"
            :interactionType="dataReport.interaction_type"
            :project="projectSelected"
            v-show="isLoaded"
          ></segments-project>-->
      <distribution-map
          v-if="isLoaded && firstVideo && Object.keys(this.firstVideo.interaction_btn_1).length && Object.keys(this.firstVideo.interaction_btn_2).length"
          :firstVideo="firstVideo"
          :videos="videos"
          :checkedVideos="checkedVideos"
          :initialInteractionAverageReport = "initialInteractionAverageReport"
          :interactionAverageReport="interactionAverageReport"
          :isLoadingMap="isLoadingMap"
          @changeInteractionAverageType="changeInteractionAverageType"
      ></distribution-map>
      <div class="intention-content mt-5"
           v-if="isLoaded"
           v-show="!isDisableIntentionArea && checkedVideos.length"
      >
        <p class="title-section mt-0">{{ intentionTitle }}</p>
        <div class="change-intention-mode mb-5 mt-5" v-if="showIntention">
          <div class="btn-group">
            <div
              class="button-change-mod"
              v-for="intentionMode in intentionModes"
              :class="{ active: selectedIntentionMode === intentionMode.value }"
              @click="changeIntentionMode(intentionMode.value)"
              :key="intentionMode.value"
              data-compare-intention-graph="action"
            >{{ intentionMode.name }}</div>
          </div>
        </div>
        <intention-area
          :intentions="listIntentions"
          :intentionLevels="intentionLevels"
          :selectedLevels="selectedIntentionLevels"
          :selectedIntentionMode="selectedIntentionMode"
          :checkedVideos="checkedVideoIntention"
          :selectedIntention="selectedIntention"
          :intentionReport="intentionReport"
          :intentionSecondReport="intentionSecondReport"
          :intentionComment="intentionComment"
          :videoComments="listVideoComments"
          :intentionCount="listVideoIntentionCount"
          :allFlg="allFlgReportIntention"
          :reportIntentionLoading="reportIntentionLoading"
          :commentIntentionLoading="commentIntentionLoading"
          :showIntention="showIntention"
          @setSelectedIntention="selectIntention"
          @setCheckedLevel="checkLevelIntention"
        ></intention-area>
      </div>
    </div>
    <div class="col-3 detail-area" v-show="isLoaded">
      <right-side
        :listRank2="listRank2"
        :listRank3="listRank3"
        :maxRank2="maxRank2"
        :maxRank3="maxRank3"
        :videos="checkedVideos"
        :interactionType="dataReport.interaction_type"
        :fromRouter="'top'"
        :isProject="isProject"
        :firstVideo="firstVideo"
        :summaryAvgData = "summaryAvgData"
        :avgs = "avgs"
        :checkedAvgIds = "checkedAvgIds"
        @checkedAvgIds= "getCheckedAvgIds"
      ></right-side>
    </div>
    <div class="background" :style="styleVideoBackground" v-if="!isLoaded">
      <div class="loader"></div>
    </div>
  </div>
  <div v-else class="top top-before">
    <div class="side-left">
      <div class="title-side-left">
        <div class="title-large">Welcome to PlayAds</div>
        <div class="title-small mt-6">
          動画コンテンツの反応値を測定して、
          <br/>
          安全かつ効果的に配信しよう！
        </div>
        <div
          class="btn btn-register btn-primary-outline"
          @click="gotoRegisterProject(clientIdSelected)"
        >
          案件を登録する
        </div>
      </div>
    </div>
    <div class="side-right bg-primary">
      <img class="img-logo" src="images/svg/logo_before_post.svg"/>
      <img src="images/svg/bg_login_logo.svg"/>
    </div>
  </div>
</template>

<script>
import SummaryChart from "@/components/Top/SummaryChart.vue";
import RightSide from "@/components/Top/RightSide.vue";
import CaptureVideo from "@/components/Top/CaptureVideo.vue";
import {
  getReportIntention,
  getReportIntentionSecond,
  getVideoIntention,
  getCommentIntention,
  interactionAverageReport,
  interactionVideoReport,
  listVideoPromise,
  reportTop,
  runBatch,
  videoImages,
  videosNormReport,
  getListVideoComments,
  getVideoIntentionCount,
} from "@/commons/api_service";
import EventBus from "@/commons/event_bus";
import { getUrlQuery } from "@/commons/helpers"
import DistributionMap from "@/components/Top/DistributionMap.vue";
import IntentionArea from "@/components/Top/Step-2/IntentionArea.vue";
import {
  interactionTypes,
  intentionLevels,
  intentionModes,
  videoIntentionStatus,
} from "@/commons/constants";
import { planTypes } from "@/commons/plan_const";

export default {
  components: {
    SummaryChart,
    RightSide,
    CaptureVideo,
    DistributionMap,
    IntentionArea
  },
  name: "TopCompare",
  props: {},
  data() {
    return {
      clients: [],
      currentUser: {},
      clientIdSelected: null,
      projectIdSelected: null,
      projects: [],
      videos: [],
      listRank2: [],
      listRank3: [],
      checkedVideos: [],
      reportData: [],
      reports: [],
      maxRank2: {},
      maxRank3: {},
      dataReport: {
        report_data: []
      },
      currentImageStep: 0,
      avgs : [{
        checked: true,
        id: "avg",
        name: "すべての動画",
        value: 0
      }
      ],
      checkedAvgIds: ["avg"],
      summaryAvg: {},
      summaryAvgData: {},
      showTopBefore: false,
      videosImages: [],
      styleVideoBackground: {
        width: 'calc(100% - 66px)',
        height: '100%',
        top: '0px',
        position: 'fixed',
        display: 'flex',
        right: 0
      },
      isLoaded: false,
      isProject: true,
      checkedPrj: true,
      firstVideo: {},
      is_stack_bar: 0,
      isLoading: false,

      isGetVideosSuccess: false,
      selectedVideoIdsFomHome: '',
      initialInteractionAverageReport: [],
      interactionAverageReport: [],
      isLoadingMap: false,
      interactionTypes: {...interactionTypes},
      // for intention
      intentions: [],
      showIntention: false,
      selectedIntention: null,
      intentionLevels: [...intentionLevels],
      selectedIntentionLevels: [],
      reportIntentionLoading: false,
      commentIntentionLoading: false,
      intentionReport: [],
      intentionSecondReport: {},
      intentionComment: [],
      allFlgReportIntention: 1,
      intentionModes: {...intentionModes},
      selectedIntentionMode: intentionModes.percentage.value,
      listVideoHasIntention: [],
      listVideoComments: [],
      listVideoIntentionCount: [],
    };
  },
  computed: {
    textInteraction() {
      return {
        rank_2: this.firstVideo && this.firstVideo.interaction_btn_1 && Object.keys(this.firstVideo.interaction_btn_1).length ? this.firstVideo.interaction_btn_1.text : '',
        rank_3: this.firstVideo && this.firstVideo.interaction_btn_2 && Object.keys(this.firstVideo.interaction_btn_2).length ? this.firstVideo.interaction_btn_2.text : '',
      }
    },
    iconInteraction() {
      return {
        rank_2: this.firstVideo && this.firstVideo.interaction_btn_1 && Object.keys(this.firstVideo.interaction_btn_1).length ? this.firstVideo.interaction_btn_1.btn_icon_url : '',
        rank_3: this.firstVideo && this.firstVideo.interaction_btn_2 && Object.keys(this.firstVideo.interaction_btn_2).length ? this.firstVideo.interaction_btn_2.btn_icon_url : '',
      }
    },
    colorInteraction() {
      let color_rank_2 = this.firstVideo && this.firstVideo.interaction_btn_1 && Object.keys(this.firstVideo.interaction_btn_1).length ? this.firstVideo.interaction_btn_1.color : '';
      let color_rank_3 = this.firstVideo && this.firstVideo.interaction_btn_2 && Object.keys(this.firstVideo.interaction_btn_2).length ? this.firstVideo.interaction_btn_2.color : ''
      return {
        rank_2: color_rank_2 == 1 ? 'type-good' : color_rank_2 == 2 ? 'type-nattoku' : color_rank_2 == 3 ? 'type-bad' : color_rank_2 == 4 ? 'type-suki' : '',
        rank_3: color_rank_3 == 1 ? 'type-good' : color_rank_3 == 2 ? 'type-nattoku' : color_rank_3 == 3 ? 'type-bad' : color_rank_3 == 4 ? 'type-suki' : '',
      }
    },
    hasNextImages() {
      let result = false;
      for (let i = 0; i < this.checkedVideos.length; i++) {
        let videoImage = this.checkedVideos[i];
        if (
          videoImage.duration &&
          videoImage.duration >= (this.currentImageStep + 1) * 9
        ) {
          result = true;
          break;
        }
      }
      return result;
    },
    isIntentionAvailable() {
      return this.currentUser.role === "admin" || Number(this.currentUser.plan) !== planTypes.free;
    },
    listIntentions() {
      let result = [];
      if (this.intentions.length) {
        this.intentions.forEach(item => {
          if (item && item.intentions) {
            item.intentions.forEach(intention => {
              if (intention && intention.id && intention.name) {
                let index = result.findIndex(e => e.id === intention.id);
                if (index === -1) {
                  result.push(intention);
                }
              }
            })
          }
        })
      }

      return result;
    },
    listIdVideoHasIntention() {
      return this.listVideoHasIntention.map(item => item.id).toString();
    },
    intentionTitle() {
      let title = "";
      if (this.listVideoHasIntention.length === this.videos) {
        title = "意向別のリアクション値"
      } else if (
          this.listVideoHasIntention.length &&
          this.videos.filter(item => item.has_cdai).length
      ) {
        title = "意向別のリアクション値とコメントディスカバリーAI"
      } else if (!this.listVideoHasIntention.length && this.videos.filter(item => item.has_cdai).length) {
        title = "コメントディスカバリーAI"
      } else {
        title = "意向別のリアクション値"
      }
      return title;
    },
    checkedVideoIntention() {
      let result = [];
      if (this.checkedVideos.length) {
        this.videos.forEach(video => {
          let index = this.checkedVideos.findIndex(item => item.id === video.id);
          if (index !== -1) {
            result.push(video);
          }
        })
      }
      return result;
    },
    isDisableIntentionArea() {
      const listVideoNotHasCdai = this.videos.filter(item => !item.has_cdai);
      return !this.listVideoHasIntention.length && listVideoNotHasCdai.length === this.videos.length;
    },
  },
  methods: {
    getAvarage(summaryAvg,avgs){
      this.summaryAvgData = summaryAvg
      this.avgs = avgs;
    },
    getCheckedAvgIds(checkAvgId){
      this.checkedAvgIds = checkAvgId;
    },
    loadedData() {
      this.isLoaded = true;
      this.checkedPrj = false;
      this.checkedVideos = [...this.videos]
    },

    onSelectVideo(video) {
      if (video.checked) {
        this.currentImageStep = 0;
        let report = this.dataReport.report_data.find(item => item.video_id == video.id);
        if (report) {
          this.$set(video, "total_2", report.tia_percent.left);
          this.$set(video, "total_3", report.tia_percent.right);
        }
        this.checkedVideos.push(video);
      } else {
        this.checkedVideos = this.checkedVideos.filter(
          (item) => item.id !== video.id
        );
      }
    },
    resetCheckedVideo() {
      this.checkedVideos = [];
    },
    clearDataRankingProject() {
      this.listRank2 = [];
      this.listRank3 = [];
      this.maxRank2 = {};
      this.maxRank3 = {};
    },
    getInteractionReportData() {
      let interactionTally = 1;
      this.clearDataRankingProject();
      interactionVideoReport((report) => {
        this.listRank2 = report["2_rank"];
        this.listRank3 = report["3_rank"];
        if (this.listRank2.length && this.listRank3.length) {
          videoImages((image) => {
            if (image) {

              let second = image.interaction_rank["2_rank"][0].second
              this.maxRank2 = {
                ...this.listRank2[0],
                second,
                image_url: image.image_url + second + ".jpg",
                interaction_count_max_per_second: image.interaction_rank["2_rank"][0].interaction_count
              }
              let maxRank2Detail = this.$store.getters.getVideosListInReportTop.find(e => e.id == this.maxRank2.video_id)
              if (maxRank2Detail && maxRank2Detail.interaction_btn_1 && Object.keys(maxRank2Detail.interaction_btn_1).length) {
                this.maxRank2.interaction_icon = maxRank2Detail.interaction_btn_1.btn_icon_url
                this.maxRank2.interaction_text = maxRank2Detail.interaction_btn_1.text
                this.maxRank2.interaction_color = maxRank2Detail.interaction_btn_1.color
              }
            }
          }, {videoId: this.listRank2[0].video_id, interactionTally});
          videoImages((image) => {
            if (image) {
              let second = image.interaction_rank["3_rank"][0].second
              this.maxRank3 = {
                ...this.listRank3[0],
                second,
                image_url: image.image_url + second + ".jpg",
                interaction_count_max_per_second: image.interaction_rank["3_rank"][0].interaction_count
              }
              let maxRank3Detail = this.$store.getters.getVideosListInReportTop.find(e => e.id == this.maxRank3.video_id)
              if (maxRank3Detail && maxRank3Detail.interaction_btn_2 && Object.keys(maxRank3Detail.interaction_btn_2).length) {
                this.maxRank3.interaction_icon = maxRank3Detail.interaction_btn_2.btn_icon_url
                this.maxRank3.interaction_text = maxRank3Detail.interaction_btn_2.text
                this.maxRank3.interaction_color = maxRank3Detail.interaction_btn_2.color
              }
            }
          }, {videoId: this.listRank3[0].video_id, interactionTally});

        }
      }, {videoIds: this.selectedVideoIdsFomHome, interactionTally});
    },
    prevImages() {
      this.currentImageStep -= 1;
    },
    nextImages() {
      this.currentImageStep += 1;
    },
    gotoVideoDetail(video_id) {
      this.$router.push({
        name: "top-detail",
        params: {
          video_id,
        },
      });
    },
    initData() {
      let query_videos = {
        buyer_id: this.clientIdSelected,
        video_ids: this.selectedVideoIdsFomHome
      };

      // eslint-disable-next-line no-unused-vars
      let query_report = {
        buyer_id: this.clientIdSelected,
        video_ids: this.selectedVideoIdsFomHome,
        interaction_tally: 1,
        is_stack_bar: this.is_stack_bar
      };

      let vm = this;
      const reportTopPromise = reportTop(query_report)
      reportTopPromise.then((data) => {
        vm.dataReport = data;
        vm.$set(vm.dataReport, 'report_data', data.report_data ? data.report_data : [])
        const listVideosPromise = listVideoPromise(query_videos)
        listVideosPromise.then((data) => {
          setTimeout(() => {
            if(data && data[0]){
              vm.videos = data;
              vm.$set(vm.videos, 0, data[0])
              vm.isGetVideosSuccess = true
              vm.firstVideo = data[0]
              vm.videos = vm.videos.map((video) => {
                video.has_cdai = video.is_comment_csv === 1 || video.is_comment_csv === 3;
                return video;
              });
              vm.getInteractionReportData();
              vm.getInteractionAverageReportData();
              vm.getVideoCommentData();
              if (vm.isIntentionAvailable) {
                const listVideoHasIntention = vm.videos.filter(item => Number(item.is_intention) === videoIntentionStatus.enabled);
                if (listVideoHasIntention.length) {
                  vm.listVideoHasIntention = listVideoHasIntention;
                  vm.showIntention = true;
                  const query_intention = {
                    buyer_id: vm.clientIdSelected,
                    video_ids: vm.listIdVideoHasIntention
                  };
                  getVideoIntention(query_intention).then((data) => {
                    vm.intentions = data && data.items ? data.items : [];
                    if(vm.listIntentions.length > 0) {
                      vm.selectedIntention = vm.listIntentions[0];
                    }
                    vm.selectedIntentionLevels = JSON.parse(JSON.stringify(vm.intentionLevels));
                    vm.getIntentionData();
                    // get intention count
                    getVideoIntentionCount({
                      video_ids: vm.listIdVideoHasIntention,
                      intention_ids: vm.listIntentions.map(item => item.id).toString()
                    }).then((data) => {
                      vm.listVideoIntentionCount = data && data.items ? data.items : [];
                    })
                  });
                }
              }
            }
          });
        })
      })


      videosNormReport((reportAvg) => {
        this.summaryAvg = reportAvg;
        this.summaryAvgData = this.summaryAvg
      }, this.clientIdSelected, {video_ids: this.selectedVideoIdsFomHome});
    },
    gotoRegisterProject() {
      this.$router.push({name: "project", query: {is_create: 1}});
    },
    handleRunBatch() {
      this.isLoading = true;
      runBatch(() => {
        this.isLoading = false;
      })
    },
    getInteractionAverageReportData(type = 0) {
      let query_report = {
        video_ids: this.selectedVideoIdsFomHome,
        type: type,
        buyer_id: this.clientIdSelected
      };

      // get interaction button text
      if(this.firstVideo) {
        query_report.interaction_button_1 = this.firstVideo.interaction_btn_1 && Object.keys(this.firstVideo.interaction_btn_1).length ? this.firstVideo.interaction_btn_1.text : ''
        query_report.interaction_button_2 = this.firstVideo.interaction_btn_2 && Object.keys(this.firstVideo.interaction_btn_2).length ? this.firstVideo.interaction_btn_2.text : ''
      }

      this.isLoadingMap = true
      interactionAverageReport((report) => {
        this.interactionAverageReport = report
        this.isLoadingMap = false
        if (Object.keys(report).length) {
          let xMin = report.left_line.min
          let xMax = report.left_line.max
          let yMin = report.right_line.min
          let yMax = report.right_line.max
          let videoAverage = report.video_average.filter(item => {
            return yMin <= item.right && item.right <= yMax && xMin <= item.left && item.left <= xMax
          })
          this.initialInteractionAverageReport = this.interactionAverageReport;
          for(let n = 0; n < this.initialInteractionAverageReport.video_average.length; n++){
            this.initialInteractionAverageReport.video_average[n].dupulicate_flg = false;
            this.initialInteractionAverageReport.video_average[n].open_flg = false;
          }
          this.interactionAverageReport = {...this.interactionAverageReport, ...{video_average: videoAverage}}
        }
      }, query_report)
    },
    changeInteractionAverageType(type) {
      this.getInteractionAverageReportData(type)
    },
    selectIntention(item){
      this.selectedIntention = item;
      this.getIntentionData()
    },
    checkLevelIntention(index) {
      const levelItem = this.intentionLevels.find(item => item.level === index);
      if (!levelItem.checkFlg) {
        levelItem.checkFlg = true;
        if (levelItem.level === 0) {
          this.allFlgReportIntention = 1;
        }
        if (!this.selectedIntentionLevels.includes(levelItem)) {
          this.selectedIntentionLevels.push(levelItem);
          this.selectedIntentionLevels.sort((a, b) => {
            if (a.level === 0) return -1;
            if (b.level === 0) return 1;
            return b.level - a.level;
          });
        }
      } else {
        levelItem.checkFlg = false;
        if (levelItem.level === 0) {
          this.allFlgReportIntention = 0;
        }
        const indexToRemove = this.selectedIntentionLevels.findIndex(item => item.level === levelItem.level);
        if (indexToRemove !== -1) {
          this.selectedIntentionLevels.splice(indexToRemove, 1);
          this.selectedIntentionLevels.sort((a, b) => {
            if (a.level === 0) return -1;
            if (b.level === 0) return 1;
            return b.level - a.level;
          });
        }
      }
      this.getIntentionData();
    },
    getIntentionData() {
      let level = this.selectedIntentionLevels.filter(item => item.level !== 0).map(item => item.level).join(',');

      let report_params = {
        video_ids: this.listIdVideoHasIntention,
        intention_id: this.selectedIntention.id,
        all_flg: this.allFlgReportIntention,
        ...(level.length ? {level: level} : {})
      };
      let comment_params = {
        video_ids: this.listIdVideoHasIntention,
        intention_id: this.selectedIntention.id,
        level: level,
      };

      let vm = this;
      vm.reportIntentionLoading = true;
      getReportIntention(report_params).then((data) => {
        vm.intentionReport = data && data.items ? data.items : [];
        getReportIntentionSecond(report_params).then((data) => {
          vm.intentionSecondReport = data ? data : {};
          vm.reportIntentionLoading = false;
        })
      })

      if (level) {
        vm.commentIntentionLoading = true;
        getCommentIntention(comment_params).then((data) => {
          vm.commentIntentionLoading = false;
          vm.intentionComment = data && data.items ? data.items : [];

        })
      } else {
        vm.commentIntentionLoading = false;
        vm.intentionComment = [];
      }
    },
    changeIntentionMode(id) {
      this.selectedIntentionMode = id;
      this.getIntentionData();
    },
    getVideoCommentData() {
      const videoIds = this.videos.map(item => item.id).toString();
      const checkCommentAvailable = this.videos.filter(item => item.is_intention !== videoIntentionStatus.enabled).length

      if (!videoIds.length || !checkCommentAvailable) {
        return;
      }
      const query_comment = {
        video_ids: videoIds,
      };

      getListVideoComments(query_comment).then((data) => {
        this.listVideoComments = data ? data : [];
      })
    },
  },
  mounted() {
    this.currentUser = this.$store.getters.currentUser;
    let {video_ids, buyer_id} = getUrlQuery();
    this.selectedVideoIdsFomHome = video_ids
    this.clientIdSelected = Number(buyer_id)
    this.$store.commit('updateSelectedVideoIdsFromHome', video_ids.split(','))
    this.initData();
  },
  created() {
    EventBus.$on("resetVideos", this.resetCheckedVideo);
  },
  beforeDestroy() {
    EventBus.$off("resetVideos");
    this.$store.commit('resetReportSummaryAd')
    this.$store.commit('resetVideosImages')
    this.$store.commit('resetReportSummaryAvg')
    this.$store.commit('resetVideoImages')
    this.$store.commit('resetInteractionRank')
    this.$store.commit('resetInteractionTop')
  },
};
</script>
<style lang="scss" scoped>
@import "@/styles/_variable.scss";

.top::v-deep {
  @import "@/styles/_top.scss";

  &.top-before {
    margin: -20px;
    display: block;
    height: 100vh;
    @media screen and (min-width: 992px) {
      display: flex;
    }

    .side-left {
      background-color: $green;
      color: $white;
      padding: 20px;
      box-sizing: border-box;
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      flex: 0 41.32vw;

      .title-side-left {
        .title-large {
          font-weight: bold;
          font-size: 36px;
          line-height: 38px;
          text-align: center;
        }

        .title-small {
          font-style: normal;
          font-weight: bold;
          @include font(xl);
        }

        .btn-register {
          padding: 10px 0px;
          margin-top: 32px;
          text-align: center;
        }
      }
    }

    .side-right {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      flex: 1;
      background-color: $green;

      .img-logo {
        position: absolute;
      }
    }
  }
}

.title-page {
  @include font(xxl);
}

.compare-data, .btn-share {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 12px;
  max-height: 28px;
  background-color: #FFFFFF;
  border: 1px solid #49AB94;
  border-radius: 8px;
  cursor: pointer;

  &:hover {
    opacity: 0.75;
  }

  span {
    color: #49AB94;
    font-style: normal;
    font-weight: 700;
    font-size: 10px;
    line-height: 16px;
  }

  .icon-action {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 16px;
    height: 16px;
    background-color: #EDF7F4;
    border-radius: 50%;

    div:first-child {
      width: 4px;
      height: 8px;
      background-color: #49AB94;
      border-radius: 1px;
    }

    div:last-child {
      width: 1px;
      height: 6px;
      margin-left: 1px;
      border: 1px solid #49AB94;
      border-radius: 1px;
    }
  }
}

.intention-content {
  .title-section {
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 38px;
    color: #333333;
    margin-bottom: 0;
  }

  .change-intention-mode {
    .btn-group {
      display: inline-flex;
      border-radius: 8px;
      border: 1px solid #999;
      overflow: hidden;

      .button-change-mod {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 13px 30px;
        font-style: normal;
        font-weight: 700;
        font-size: 15px;
        line-height: 24px;
        cursor: pointer;
        color: #999;
        width: max-content;

        &.active {
          color: #fff;
          background-color: #49ab94;
        }
      }
    }
  }
}
</style>
<style type='text/css'>
@-moz-document url-prefix() {
  .video-list .body {
    direction: ltr !important;
    scrollbar-width: thin
  }
}

.background .loader {
  border: 3px solid #49ab94;
  border-radius: 50%;
  border-top: 2px solid #ffffff;
  width: 36px;
  height: 36px;
  -webkit-animation: spin 1s linear infinite; /* Safari */
  animation: spin 1s linear infinite;
  margin: auto;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media screen and (max-width: 1440px)  {
  .detail-area {
    flex: unset !important;

    .interaction-percentage {
      font-size: 15px !important;
      width: 50px;
    }

    .body-left {
      width: 39.5% !important;
    }
  }
}

@media screen and (max-width: 1279px)  {
  .detail-area {
    .card > .content > .body {
      flex-wrap: wrap !important;
      .body-left {
        width: 100% !important;
      }
    }
  }
}

</style>
