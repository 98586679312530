<template>
  <div class="intention-chart mt-4">
    <div v-if="!reportDataIsLoading && !commentDataIsLoading" class="chart-inner" id="chart-inner">
      <div class="chart-area">
        <div class="left-area">
          <div class="header gray first-header" :class="{'only-header': selectedLevel.length == 0 && !isOnlyComment}">
            {{!isOnlyComment ? "リアクション\\動画名" : '動画名'}}
          </div>
          <template v-if="selectedLevel.length || isOnlyComment">
            <div
                v-for="(level, index) in selectedLevel"
                :key="level.level"
                class="change-area"
                v-show="!isOnlyComment"
                :class="{'last-item': index == selectedLevel.length - 1 && isOnlyReportIntention}"
            >
              <div class="level gray">
                <p class="m-0">{{ level.value }}</p>
              </div>
              <div class="interaction">
                <div class="interaction-name">
                  <div class="all">
                    <p>意向度</p>
                  </div>
                  <div class="all border-top">
                    <p>リアクション</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="word-cloud" v-show="!isOnlyReportIntention">
              <div class="word-cloud-inner">
                <p class="title">ワードクラウド</p>
                <div class="select-level" v-show="!isOnlyComment">
                  <p>以下の意向を反映</p>
                  <ul class="word-cloud-select-level">
                    <li v-for="level in selectedLevel" :key="level.level">
                      {{ level.value }}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </template>
        </div>
        <div class="right-area">
          <div v-for="(video, index) in intentionSecondReportData" :key="`video-${index}`" class="interaction-chart">
            <div class="header video-name gray">
              <a :title="video.name" @click="gotoDetailVideo(video.id)" data-compare-intention-graph="action">{{ video.name }}</a>
            </div>
            <template v-if="selectedLevel.length || isOnlyComment">
              <div class="interaction-values" v-show="!isOnlyComment">
                <template v-for="(levelData) in listIntentionLevelId">
                  <div class="data-group"
                       v-if="video.report[`level_${levelData}_interactions`]"
                       :key="`data-group-${levelData}`"
                  >
                    <div class="data-raw"
                         v-if="Object.keys(video.report[`level_${levelData}_interactions`]).length"
                    >
                      <div class="border-top">
                        <div class="count-data"
                             v-if="video.report[`level_${levelData}_interactions`].values.count"
                             :style="{width: `${video.report[`level_${levelData}_interactions`].values.count}%`}">
                          <p>{{ roundPercent(video.report[`level_${ levelData }_interactions`].values.count) }}%</p>
                        </div>
                        <div class="count-data"
                             v-else-if="video.is_intention == videoIntentionStatus.enabled"
                             :style="{width: `0%`}"
                        >
                          <p>0.0%</p>
                        </div>
                        <div v-else class="count-data no-data">
                          <p>意向を取得していません</p>
                        </div>
                      </div>
                      <div class="interaction-value">
                        <LineChart
                          :video="video"
                          :seriesData="video.report[`level_${levelData}_interactions`].values"
                          :isTotalReaction="totalReactionFlg"
                          :isShowImageIntention="isShowImageIntention"
                          :maxValue="maxInteraction"
                          :maxDuration="maxDuration"
                        >
                        </LineChart>
                      </div>
                    </div>
                    <div v-else class="data-raw">
                      <div class="border-top">
                        <div class="count-data no-data">
                          <p>意向を取得していません</p>
                        </div>
                      </div>
                      <div class="interaction-value no-data"><p>意向を取得していません</p></div>
                    </div>
                  </div>
                </template>
              </div>
              <div class="word-cloud-outer" v-show="!isOnlyReportIntention">
                <div
                  v-if="Object.prototype.hasOwnProperty.call(video, 'comment')"
                  class="word-cloud-iframe"
                  :class="`word-cloud-${video.id}`"
                  :data-html="video.comment.word_cloud_html"
                >
                </div>
                <div v-else
                     class="word-cloud-iframe-empty"
                >
                  <p>コメントを取得していません</p>
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="loading-report">
      <div class="background">
        <div class="loader"></div>
      </div>
    </div>
    <div id="content-check-bottom"></div>
  </div>
</template>
<script>
import Line from "@/components/Charts/Step2/Line.vue";
import { intentionWordCloudModify } from "@/commons/mixins";
import {videoIntentionStatus} from "@/commons/constants";

export default {
  name: 'IntentionLineChart',
  components: {
    'LineChart': Line,
  },
  mixins: [intentionWordCloudModify],
  data() {
    return {
      videoIntentionStatus,
    }
  },
  computed: {
    intentionSecondReportData() {
      let data = JSON.parse(JSON.stringify(this.videos));
      const currentCountData = this.intentionCount.find((data) => {
        return data.intention_id === this.selectedIntention.id;
      });
      data.forEach((video) => {
        video.report = {};
        if (Object.keys(this.intentionSecondReport).length > 0) {
          this.intentionSecondReport.items.forEach((report) => {
            if (video.id === report.video_id) {
              video.report = report;
              if (video.report.all_interactions) {
                video.report.level_0_interactions = {
                  level: 0,
                  values: {
                    ...video.report.all_interactions,
                    count: 100,
                  },
                };
              }
            }
          });
        }
        // if video not have report data
        if (!Object.keys(video.report).length) {
          this.selectedLevel.forEach((level) => {
            video.report[`level_${ level.level }_interactions`] = {};
          });
        }

        this.intentionComment.forEach((comment) => {
          if (video.id === comment.video_id && video.has_cdai) {
            video.comment = comment;
          }
        });

        // for count intention
        this.videoComments.forEach((comment) => {
          if (video.id === comment.video_id && video.has_cdai && !video.comment) {
            video.comment = comment;
          }
        });

        if (currentCountData && currentCountData.datas && currentCountData.datas.length) {
          const countData = currentCountData.datas.find((item) => {
            return item.video_id === video.id;
          });

          if (countData && countData.count_datas && countData.count_datas.length) {
            this.selectedLevel.forEach((level) => {
              if (Object.keys(video.report[`level_${ level.level }_interactions`]).length) {
                const countValue = countData.count_datas.find((item) => {
                  return item.level === level.level;
                });

                if (countValue) {
                  video.report[`level_${ level.level }_interactions`].values.count = countValue.user_count;
                }
              }
            });
          } else {
            if (video.report['level_0_interactions'] && video.report['level_0_interactions'].values) {
              video.report['level_0_interactions'].values.count = 0;
            }
          }
        }

      });
      return data
    },
    maxInteraction() {
      const totalMax = this.intentionSecondReport.max_interaction;
      let max = totalMax > 0 ? Math.ceil(totalMax / 10) * 10 + 10 : 50;
      if(max > 100) max = 100;
      return max;
    },
    maxDuration() {
      return this.intentionSecondReport.max_duration
    },
  },
  methods: {
    roundPercent(percent) {
      if (typeof percent == "number" && percent < 100) return percent.toFixed(1)
      return percent;
    },
  },
  watch: {
    intentionSecondReport: function () {
      this.initVisibleIframe();
      this.initSetSlider();
    }
  },
}
</script>
<style lang="scss" scoped>
@import "@/styles/_intention_chart_top_compare.scss";
.left-area {
  .change-area {
    height: 184px;
    .level {
      width: 70px;
      border-right: 1px solid #999999;
    }

    .interaction {
      .interaction-name {
        .all {
          background-color: #fff;

          &.border-top {
            height: 145px !important;
            display: flex;
            align-items: center;
            background-color: #fff;
          }
        }
      }
    }
  }
}
.right-area {
  .interaction-chart {
    width: 290px !important;

    .interaction-values {
      .count-data {
        height: 38px;
        display: flex;
        align-items: center;
        background-color: #EDF7F4;

        p {
          margin-left: 20px;
          font-weight: bold;
          color: #49AB94;
        }
      }
      .interaction-value {
        height: 145px;
        display: flex;
        align-items: flex-end;
        border-top: 1px solid #999999;
        ::v-deep {
          div > div {
            overflow: visible !important;
          }
        }
      }

      .no-data {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #fff;
        p {
          font-weight: 400;
          color: #999999;
          margin-left: 0;
        };
      }
    }
  }
}
</style>